<template>
    <div class="Sale projectCredit">
        <titleXS></titleXS>
        <ul class="grade">
            <li>
                <button class="iconfont iconbianji"></button>
                <!-- <button class="iconfont iconbianji preservation"></button> -->
                <div class="subsection">
                    <i class="el_left">A</i>
                    <p class="el_left">
                        <input
                            type="text"
                            value="8"
                        >
                        <span>~</span>
                        <input type="text">
                        <strong>分</strong>
                    </p>
                </div>
                <p class="explain el_left">
                    说明
                </p>
            </li>
            <li>
                <!-- <button class="iconfont iconbianji"></button> -->
                <button class="iconfont iconqueding preservation"></button>
                <div class="subsection">
                    <i class="el_left">B</i>
                    <p class="el_left">
                        <input
                            type="text"
                            value="8"
                        >
                        <span>~</span>
                        <input type="text">
                        <strong>分</strong>
                    </p>
                </div>
                <p class="explain el_left">
                    说明
                </p>
            </li>
            <li>
                <!-- <button class="iconfont iconbianji"></button> -->
                <button class="iconfont iconbianji"></button>
                <div class="subsection">
                    <i class="el_left">C</i>
                    <p class="el_left">
                        <input
                            type="text"
                            value="8"
                        >
                        <span>~</span>
                        <input type="text">
                        <strong>分</strong>
                    </p>
                </div>
                <p class="explain el_left">
                    说明
                </p>
            </li>
            <li>
                <!-- <button class="iconfont iconbianji"></button> -->
                <button class="iconfont iconbianji"></button>
                <div class="subsection">
                    <i class="el_left">D</i>
                    <p class="el_left">
                        <input
                            type="text"
                            value="8"
                        >
                        <span>~</span>
                        <input type="text">
                        <strong>分</strong>
                    </p>
                </div>
                <p class="explain el_left">
                    说明
                </p>
            </li>
            <li>
                <!-- <button class="iconfont iconbianji"></button> -->
                <button class="iconfont iconbianji"></button>
                <div class="subsection">
                    <i class="el_left">E</i>
                    <p class="el_left">
                        <input
                            type="text"
                            value="8"
                        >
                        <span>~</span>
                        <input type="text">
                        <strong>分</strong>
                    </p>
                </div>
                <p class="explain el_left">
                    说明
                </p>
            </li>
        </ul>
        <ul class="el_left projectCredit_l">
            <li class="active">
                <div class="el_left node">
                    <p class="nodeName">
                        <i class="iconfont  iconfeiyong"></i>
                        回款
                    </p>
                    <input
                        class="explain"
                        type="text"
                        placeholder="在此处填写说明文字。。。"
                    >
                </div>
                <div class="el_right divInput">
                    50%
                </div>
            </li>
            <li>
                <div class="el_left node">
                    <p class="nodeName">
                        <i class="iconfont  icontiji_o"></i>
                        方量
                    </p>
                    <input
                        class="explain"
                        type="text"
                        placeholder="在此处填写说明文字。。。"
                    >
                </div>
                <div class="el_right divInput">
                    50%
                </div>
            </li>
            <li>
                <div class="el_left node">
                    <p class="nodeName">
                        <i class="iconfont  iconjiage"></i>
                        价格
                    </p>
                    <input
                        class="explain"
                        type="text"
                        placeholder="在此处填写说明文字。。。"
                    >
                </div>
                <div class="el_right divInput">
                    50%
                </div>
            </li>
            <li>
                <div class="el_left node">
                    <p class="nodeName">
                        <i class="iconfont  iconzhangdanBill"></i>
                        账龄
                    </p>
                    <input
                        class="explain"
                        type="text"
                        placeholder="在此处填写说明文字。。。"
                    >
                </div>
                <div class="el_right divInput">
                    50%
                </div>
            </li>
            <li>
                <div class="el_left node">
                    <p class="nodeName">
                        <i class="iconfont  iconhezuo"></i>
                        合作过程
                    </p>
                    <input
                        class="explain"
                        type="text"
                        placeholder="在此处填写说明文字。。。"
                    >
                </div>
                <div class="el_right divInput">
                    50%
                </div>
            </li>
        </ul>
        <div class="el_right table">
            <currencyTable></currencyTable>
        </div>
    </div>
</template>

<script>
import './css/saleStyle.styl';
import titleXS from '@/components/title/title_xs.vue';
import currencyTable from '@/components/currencyTable/index.vue';

export default {
    // 项目信用评级设置页面
    name: 'project-credit',
    components: {
        titleXS,
        currencyTable,
    },
    props: {},
    data() {
        return {};
    },
    watch: {},
    computed: {},
    methods: {},
    created() { },
    mounted() { },
};

</script>
<style lang="stylus" scoped>
</style>
