<template>
    <div>
        <div class="header_Sale">
            <span class="el_left">生效时间</span>
            <div class="block el_left">
                <el-date-picker
                    v-model="validTimeValue"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    :picker-options="pickerOptions"
                    @change="preserveBasicInfo()"
                >
                </el-date-picker>
            </div>
            <!-- <el-button class="el_left" type="primary">
                保存
            </el-button> -->
            <el-button
                class="el_left"
                type="primary"
                v-if="!isRelease"
                @click="release"
            >
                发布
            </el-button>
            <el-select
                class="el_left"
                v-model="historyID"
                placeholder="请选择"
                @change="selectionPeriod"
            >
                <el-option
                    v-for="item in history"
                    :key="item.period"
                    :label="item.period_str"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-button
                type="primary"
                :loading="buttonLoading"
                class="el_left"
                v-if="isRelease"
                @click="generateNewPeriods"
            >
                生成新期数
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    // 销售业务子页面头部
    name: 'sales-business-title',
    components: {},
    props: {
        history: {
            type: [Array, Object, String ],
        },
        isRelease: {
            type: [Array, Object, String, Boolean ],
        },
        buttonLoading: {
            type: [Boolean],
        },
        timeValue: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            // // 生效时间
            validTimeValue: '',
            // 当前打开期数
            historyID: '',
            // 页面初始化数据
            initData: '',
            // 时间控件快捷选项
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    },
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    },
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    },
                }],
            },
        };
    },
    watch: {
        history(newVal) {
            this.historyID = newVal[0].id;
        },
        timeValue(newVal) {
            this.validTimeValue = newVal;
        },
    },
    computed: {},
    methods: {
        // 选择期数 切换数据
        selectionPeriod() {
            this.$emit('selectionPeriodCallback', this.historyID);
        },
        // 发布
        release() {
            this.$emit('release');
        },
        // 选择日期更新基础信息
        preserveBasicInfo() {
            this.$emit('preserveBasicInfo', this.validTimeValue);
        },
        // 生成新期数
        generateNewPeriods() {
            this.$emit('generateNewPeriods');
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
.header_Sale
    width 100%
    height 0.8rem
    background #fff
    >span
        font-size 0.16rem
        line-height 0.8rem
        padding 0 0.2rem
    .el-date-editor,
    .el-button,
    .el-select
        margin 0.2rem 0.6rem 0.2rem 0
    .el-date-editor
        input
            height 0.4rem
            line-height 0.4rem
        .el-input__icon
            width 0.25rem
            line-height 0.4rem
    .el-button
        padding 0
        height 0.4rem
        padding 0 0.2rem
    .el-select
        .el-input__inner
            height 0.4rem
            line-height 0.4rem
        .el-input__icon
            width 0.25rem
            line-height 0.4rem
</style>