<template>
    <div class="currencyTable">
        <template>
            <el-table
                stripe
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="date"
                    label="日期"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="地址"
                >
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄',
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄',
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄',
            }],
        };
    },
};
</script>
<style lang="stylus">
.currencyTable
    border 1px solid #D7D7D7
    border-radius 8px
    overflow hidden
    .el-table
        width 100%
        border 1px solid #F0F0F0
        border-radius 2px
        .el-table__header-wrapper
            background #EBEFF4
        td, th
            padding 5px 0
            height 0.36rem
            border none
            text-align center
        th
            background #EDF0F5
            color #022782
        td
            height 0.36rem
            color #404652
            text-align center
            div
                white-space nowrap
                overflow hidden
                text-overflow ellipsis
    .el-table--striped .el-table__body tr.el-table__row--striped td
        background #F8FAFD
    .el-table--enable-row-hover .el-table__body tr:hover>td
        background #EDF3FB
    .table-page
        margin-top 10px
        text-align right
        li
            color #979EA7
            &.active,
            &:hover
                color #D40004
        .el-pagination__jump
            .el-input__inner
                color #D40004
        .el-pagination__total,
        .el-pagination__jump
            color #979EA7
    .btable-tooltip
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
    .el-table__empty-text
        width 100%
        height 100%
        font-size 0
        background url('./../../assets/images/default/list_nothing1.png') center no-repeat
</style>
